html {
    position: relative;
    min-height: 100%
}

body {
    background-color: #ebeff3;
}

@supports (-webkit-overflow-scrolling:touch) {
    body {
        cursor: pointer
    }
}

.bg-cover {
    background: no-repeat 50%/cover;
}

.btn-primary {
    color: $white;
    background-color: darken($primary, 10%);
    border-color: darken($primary, 15%);
    background-image: linear-gradient(to bottom, $primary, darken($primary, 10%));
    &:hover {
        color: $white;
        background-color: darken($primary, 10%);
        border-color: darken($primary, 15%);
        background-image:none;
    }
}
[data-bs-theme=dark] .btn-primary {
    color: $gray-900;
    &:hover {
        color: $gray-900;
    }
}

.dropdown-toggle.no-arrow {
    &:after {
        display: none;
    }
}

.logo {
    display: block;
    line-height: 70px;
    width: 200px;
    position: fixed;
    top: 0;
}

.logo span.logo-lg {
    display: block;
    img {
        width: 160px;
    }
}

.logo span.logo-sm {
    display: none;
    img {
        width: 30px;
    }
}

.logo.logo-light {
    display: block
}

.logo.logo-dark {
    display: none
}

.wrapper {
    padding-left: 200px;
}

.content-wrapper {
    float:left;
    overflow-x: clip;
    height: 100%;
    position: relative;
    width: 100%;
    
}

.content-container {
    padding: 10px;
}

.content-section {
    background-color: var(--bs-body-bg);
    padding: 1rem;
    min-height: calc(100vh - 72px);
}

@media (max-width: 767.98px) {
    body {
        overflow-x:clip
    }

    .left-menu {
        display: none;
        z-index: 10000!important
    }

    .sidebar-enable .left-menu {
        display: block
    }

    .navbar-nav.navbar-right {
        float: right
    }

    .content-page {
        margin-left: 0!important;
        padding: 65px 0 65px
    }

    body[data-leftbar-compact-mode=condensed] .left-menu {
        margin-left: 0
    }

    .logo span.logo-lg {
        display: block
    }

    .logo span.logo-sm {
        display: none
    }
}

//---------------------------------------------
// Messages
//---------------------------------------------

.messages {
    background-color: $gray-600;
    position: relative;
    ul {
        margin: 0;
        opacity: 0;
        padding: 0;
        position: relative;
        top: -100px;
        list-style-type: none;
        &.appear {
            opacity: 1;
            top: 0;
            transition: top .5s ease, opacity .5s ease, max-height 1.2s ease;
        }
        li {
            list-style-type: none;
            color: $white;
            border-bottom: 1px solid #0000;
            padding: 1.6em 3em 1.6em 1.6em;
        }
    }
    .error {
        background-color: $danger;
    }
    .warning {
        background-color: $warning;
        color: var(--bs-body-color);
    }
}

.has-messages {
    .messages {
        ul {
            opacity: 1;
            top: 0;
            transition: top .5s ease, opacity .5s ease, max-height 1.2s ease;
        }
    }
}

.user-select-all {
    border: 1px dotted transparent;
    &:hover {
        border: 1px dotted $primary;
        border-radius: 3px;
    }
}

//---------------------------------------------
// Auth
//---------------------------------------------

.auth-fluid {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;

    a {
        &:hover {
            text-decoration: underline;
        }
    }

    .footer {
        bottom: 0;
        padding: 19px 24px 20px;
        position: absolute;
        right: 0;
        color: $gray-600;
        left: 0;
        transition: all .2s ease-in-out;
    }
}

.auth-fluid .auth-fluid-form-box {
    max-width: 600px;
    border-radius: 0;
    z-index: 2;
    padding: 3%;
    background-color: #FDFDFD;
    position: relative;
    width: 100%
}

.auth-fluid .auth-fluid-left,
.auth-fluid .auth-fluid-right {
    padding: 6rem 3rem;
    flex: 1;
    position: relative;
    color: #fff;
    background-position: center;
    background-size: cover;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0,0,0,.3);
    }
}

.auth-brand {
    margin-bottom: 2rem
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;
    z-index: 1;
}

.auth-user-testimonial p.lead {
    font-size: 1.125rem;
    margin: 0 auto 20px auto;
    max-width: 700px
}

@media (min-width: 992px) {
    .auth-brand {
        position:absolute;
        top: 3rem
    }
}

@media (max-width: 991.98px) {
    .auth-fluid {
        display:block
    }

    .auth-fluid .auth-fluid-form-box {
        max-width: 100%;
        min-height: 100vh
    }

    .auth-fluid .auth-fluid-left,
    .auth-fluid .auth-fluid-right {
        display: none
    }
}

//---------------------------------------------
// Header
//---------------------------------------------

.header-wrapper {
    position: sticky;
    top: 0;
    z-index: 1000;
    border-left: 1px solid var(--bs-body-bg);
    
    .header {
        position: sticky;
        margin: 0;
        padding: 0;
        z-index: 100;
        background-color: #f5f7f9;
        border-bottom: 1px #cfd7df solid;
        box-shadow: 0 2px 4px 0 rgba(24,50,71,.08);
        .page-title {
            margin: 1rem;
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
            strong {
                font-weight: normal;
            }
            span {
                padding: 0 0.5rem;
            }
        }
    }
}

.list {
    .page-header {
        padding: 1rem 2rem;
        background: $white;
        .page-title {
            color: $gray-900;
            font-size: 20px;
            margin: 0;
            line-height: 60px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.top-bar {
    height: 50px;
    background-color: var(--bs-body-bg);
    border-bottom: 1px solid var(--bs-secondary-bg);
}

[data-bs-theme=dark] {
    .top-bar {
        background-color: var(--bs-tertiary-bg);
    }
}

#ajax-login-wrapper {
    border-left: 1px solid var(--bs-secondary-bg);
    border-right: 1px solid var(--bs-secondary-bg);
    min-width:302px;
    height: 50px;
}

.search-form-wrapper {
    input {
        border: none;
        background-color: var(--bs-body-bg);
        color: var(--bs-body-color);
        outline: 0;
        font-size: 14px;
        height: 48px;
        width: calc(100% - 2rem);
      }
      
      input:focus {
        border: none;
        background-color: none;
        outline: 0;
      }
}

[data-bs-theme=dark] {
    .search-form-wrapper {
        input {
            background-color: var(--bs-tertiary-bg);
        }
    }
}

.dashboard-search-results {
    position: fixed;
    top: 49px;
    z-index: 10000;
    border: 1px solid var(--bs-secondary-bg);
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: var(--bs-body-bg);
    overflow: hidden;
    overflow-y: scroll;
    height: auto;
    max-height: 50vh;
    .search-result-type {
        display: block;
        background-color: var(--bs-secondary-bg);
        border-bottom: 1px solid var(--bs-secondary-bg);
        padding: 10px 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .search-no-results {
        padding: 10px 20px;
    }
    .search-result {
        display: block;
        border-bottom: 1px solid var(--bs-secondary-bg);
        padding: 10px 20px;
        text-decoration: none;
        transition: all 0.4s;
        &:hover {
            background-color: var(--bs-tertiary-bg);
        }
    }
}

[data-bs-theme=dark] {
    .dashboard-search-results {
        input {
            background-color: var(--bs-tertiary-bg);
        }
    }
}

.user-profile-dropdown {
    color: var(--bs-body-color);
    display: block;
    margin-right: 0.5rem;
    text-decoration: none;
}

.user-display {
    display: inline-block;
    margin: 0 0.5em 0 0;
    max-width: 200px;
    transform: translateY(25%);
}

.user-profile {
    display: inline-block;
    margin: 0 0.5em; 
}

[data-initials] {
    &:before {
        background: $primary;
        color: $white;
        opacity: 1; 
        content: attr(data-initials); 
        display: inline-block; 
        font-size: 12px;
        font-weight: 500; 
        border-radius: 32px; 
        vertical-align: middle; 
        width: 32px; 
        height: 32px; 
        line-height: 32px; 
        text-align: center;
    }
}

.user-switcher,
.no-user-switcher {
    position: relative;
    display: inline-block;
    min-width: 300px;
    height: 50px;
    line-height: 50px;
    padding-left: 16px;
    padding-right: 48px;
    color: var(--bs-body-color);
    text-decoration: none;
    &.dropdown-toggle {
        &:after {
            display: none;
        }
    }
    i {
        position: absolute;
        right: 8px;
    }
}


.dropdown-menu.scrollable {
    height: auto;
    max-height: 200px;
    min-width: 300px;
    overflow-x: hidden;
    width: calc(100% - 0.5rem - 10px);
}

.user-filter-wrapper {
    padding: 3px 8px 12px 8px;
    background-color: var(--bs-body-bg);
    border-bottom: 1px solid var(--bs-secondary-bg);
    top: -0.5rem;
}

.global-add-button {
    font-size: 32px;
    color: $primary;
    &.dropdown-toggle {
        &:after {
            display: none;
        }
    }
    &:hover {
        color: darken($primary, 10%);
    }
}


.listing-section {
    padding: 1.5rem;
}

.sticky-table-header {
    position: sticky;
    z-index: 1;
}

//-------------------------
// Pagination
//-------------------------
.page-link {
    position: relative;
    display: block;
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.pagination-rounded .page-link {
    border-radius: 6px!important;
    margin: 0 3px!important;
    border: none
}

//------------------------
// Tabs
//------------------------
.listing-tabs {
    .nav {
        max-width: 1400px;
    }
    .nav-underline {
        li {
            text-transform: uppercase;
            font-size: 16px;
            
            a.error {
                color: $danger !important;
            }
            .nav-link {
                border-width: 3px;
                padding-top: 16px;
                padding-bottom: 16px;
                &:hover {
                    border-image: linear-gradient(to right, $primary, darken($primary, 10%)) 1;
                }
                &.active {
                    border-image: linear-gradient(to right, $primary, darken($primary, 10%)) 1;
                    font-weight: 500;
                    &.error {
                        border-image: linear-gradient(to right, $danger, darken($danger, 10%)) 1;
                    }
                }
            }
        }
    }
    i {
        font-size: 16px;
    }
    .tab-icon {
        display: inline-block;
        margin: 0;
        width: 16px;
        height: 16px;
        opacity: 0.5;
    }
    .active {
        .tab-icon {
            opacity: 0.9;
        }
    }
    .nav-pills {
        margin-bottom: 2rem;
        li {
            a.error {
                background-color: $danger !important;
                color: $white !important;
            }
        }
    }  
}

.dashboard {
    del {
        text-decoration: none;
        background-color: #ffecf0;
        color: #c82124;
    }
    ins {
        text-decoration: none;
        background-color: #e0f5f0;
        color: #007958;
    }
}

.table {
    th {
        background-color: var(--bs-tertiary-bg);
        font-size: 12px;
        text-transform: uppercase;
        a {
            color: var(--bs-body-color);
            text-decoration: none;
        }
    }
}

// Custom badges
.status {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
    padding: 0 4px;
}
.status {
    &__draft,
    &__website {
        background-color: var(--bs-secondary-bg);
        color: var(--bs-body-color);
    }
    &__published,
    &__domain {
        background-color: #e0f5f0;
        color: #007958;
        border:1px solid #b4e5d9;
    }
    &__available {
        background-color: #e0f5f0;
        color: #007958;
        border:1px solid #b4e5d9;
    }
    &__sold,
    &__rea {
        background-color: #fef1e1;
        color: #c13e1b;
        border:1px solid #fddbb5
    }
    &__leased {
        background-color: #fef1e1;
        color: #c13e1b;
        border:1px solid #fddbb5
    }
    &__offmarket,
    &__withdrawn,
    &__deleted {
        background-color: #ebeff3;
        color: #384551;
        border:1px solid #ebeff3
    }
    &__deposittaken,
    &__underoffer,
    &__undercontract {
        background-color: var(--bs-warning-bg-subtle);
        color: var(--bs-warning-text);
    }
    &__error {
        background-color: #ffecf0;
        color: #c82124;
        border:1px solid #ffd0d6;
    }
    &__warning {
        background-color: #fff9f0;
        color: #ff8700;
        border:1px solid #fedfaf;
    }
    &__facebook {
        background-color: $blue-200;
        color: $blue-800;
    }
    &__type {
        background-color: $white;
        border: 1px solid #cfd7df;
        color: var(--bs-body-color);
    }
}

.cursor {
    &__help {
        cursor: help;
    }
}


/*
    Listings List
*/
.listings {
    &__list {
        position: relative;
        min-height: 80px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 1px 0 0 #cfd7df;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 8px;
        padding: 12px;
        font-size: 12px;
        &.error {
            &::before {
                content: "";
                position: absolute;
                width: 2px;
                left: 0;
                top: 0;
                bottom: 0;
                background-color: #c82124;
                transition: 10ms linear;
                border-radius: 2px 0 0 2px;
            }
            background-color: #ffecf0;
        }
        .listing-thumb {
            position: absolute;
            background-color: var(--bs-secondary-bg);
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 4px;
            overflow: hidden;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            color: var(--bs-body-color);
            text-transform: uppercase;
            font-size: 16px;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
        .listing-content {
            margin-left: 80px;
            font-size: 12px;
        }
        .listing-tags {
            .badge {
                font-size: 11px;
            }
        }
        .listing-summary {
            font-size: 14px;
            margin-top: 6px;
            margin-bottom: 6px;
            a {
                color: var(--bs-body-color);
                text-decoration: none;
                font-weight: 500;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

/*
    Reviews List
*/
.reviews {
    &__list {
        position: relative;
        min-height: 80px;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 1px 0 0 #cfd7df;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 8px;
        padding: 12px;
        font-size: 14px;
        
        .listing-tags {
            .badge {
                font-size: 11px;
            }
        }
        
        a {
            color: var(--bs-body-color);
            text-decoration: none;
            font-weight: 500;
            &:hover {
                color: $primary;
            }
        }
    }
}

.modal-header {
    background: linear-gradient(90deg, $primary,#4082c4);
    .modal-title {
        color: $white;
    }
    .btn-close {
        filter: var(--bs-btn-close-white-filter);
    }
}
