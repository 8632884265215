//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-size-base: 0.875rem;
$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$primary: #92C44C;

$link-color-dark: $primary;
$link-hover-color-dark: lighten($primary, 20%);

$td-active-bg: $primary;
$td-dark-active-bg: $primary;

$card-bg: #f5f7f9;

$accordion-icon-color: var(--bs-body-color);
$accordion-button-active-bg: var(--bs-tertiary-bg);
$accordion-button-active-color: var(--bs-body-color);
$accordion-icon-active-color: var(--bs-body-color);


//---------------------------------------------
// Forms
//---------------------------------------------
$form-label-font-weight: 500;
$input-font-size: 0.875rem;

//---------------------------------------------
// Tables
//---------------------------------------------
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;
$table-hover-bg: #ebeff3;

//---------------------------------------------
// Tabs
//---------------------------------------------
$nav-link-color: rgba(33, 37, 41, 0.65);
$nav-link-hover-color: rgba(33, 37, 41, 0.75);
